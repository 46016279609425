@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.sectionContainer {
  align-items: center;

  @include p-size-mobile {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 32px 24px;
  }
}

.infoContainer {
  line-height: 16px;
  flex: 1 1 auto;

  @include p-size-mobile {
    order: 1;
    flex: 0 0 50%;
  }
}

.statusContainer {
  flex-direction: column;
  height: 92px;

  @include p-size-mobile {
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    order: 3;
    flex: 0 0 100%;
  }
}

.icon {
  width: 90px;
  height: 90px;
  display: block;

  @include p-size-mobile {
    width: 72px;
    height: 72px;
  }
}

.row {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.info {
  @include p-size-tablet {
    display: flex;
    flex-wrap: wrap;
  }

  @include p-size-mobile {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.chevronOpen {
  transition: filter 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.chevronClosed {
  transform: rotate(180deg);
}

.document {
  display: flex;
  justify-content: space-between;

  text-decoration: none;
  border: 1px solid $ds-grey-300;
  border-radius: 8px;

  &:hover {
    border: 1px solid $ds-purple-500;
    transition: 0.2s ease-in;

    &::after {
      @keyframes appearInAnimation {
        from {
          opacity: 0;
          transform: translateY(-5px);
        }
        to {
          opacity: 1;
        }
      }

      content: '';

      height: 24px;
      width: 24px;

      background-image: url('./icons/download.svg');

      animation-name: appearInAnimation;
      animation-duration: 0.3s;
      animation-fill-mode: both;
    }
  }

  &:focus {
    outline: 2px solid $ds-purple-500;
  }

  @include p-size-mobile {
    &::after {
      content: '';
      opacity: 1;
      height: 24px;
      width: 24px;
      background-image: url('./icons/download.svg');
    }
  }
}

.document:first-child {
  margin-top: 0;
}

.documentName {
  margin-left: 8px;

  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include p-size-mobile {
    max-width: 170px;
  }
}

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.badge {
  padding-top: 6px;
  margin-left: 12px;
  height: 30px;
  min-width: 80px;
}

.vertical-line {
  height: 1px;
}

.img {
  @include p-size-mobile {
    order: 2;
  }
}

.yellowBorder {
  border: 1px solid $ds-yellow-500;
}
